.containerCategory{
    background-color: #61dafb;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 10px;
  }
  
  .ModelType{
      background-color: royalblue;
      /* width: 20px; */
      margin-bottom: 10px;
      margin-top: 10px;
  }
  
  
  .featuredItemModel {
      flex: 1;
      margin: 0px 20px;
      padding: 30px;
      border-radius: 10px;
      cursor: pointer;
      background-color: rgb(240, 240, 255);
      -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    }
  
  
  
  
  
  
  
  .featured {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .featuredItem {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: rgb(251, 251, 255)
  }
  
  .featuredTitle{
      font-size: 20px;
  }
  
  .featuredMoneyContainer{
      margin: 10px 0px;
      display: flex;
      align-items: center;
  }
  
  .featuredMoney{
      font-size: 15px; 
      font-weight: 600;
  }
  
  .featuredMoneyRate{
      display: flex;
      align-items: center;
      margin-left: 20px;
  }
  
  .featuredIcon{
      font-size: 14px;
      margin-left: 5px;
      color: green;
  }
  
  .featuredIcon.negative{
      color: red;
  }
  
  .featuredSub{
      font-size: 15px;
      color: gray;
  }
  
  
  .featuredSubArchitecture{
      margin-left: 10px
  }
  
  .spacingBtwnContainer{
      margin-bottom: 10px;
  }