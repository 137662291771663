.file-exists {
    text-align: left;
    color: red;
  }
  
  .drag-drop-zone {
    padding: 2rem;
    text-align: center;
    background: #F6A8CC;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .drag-drop-zone p {
    color: black;
  }
  
  .drag-drop-zone.inside-drag-area {
    opacity: 0.7;
  }
  .dropped-files{
      margin: 20px;
      background-color: #F6A8CC;
      border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .dropped-files li {
    color: black;
    padding: 10px;
    text-align: left;
    font-weight: bold;
    margin: 10px;
  }

  .dropped-files p {
    color: black;
    padding: 10px;
    text-align: left;
    font-weight: bold;
    margin: 10px;
  }