.user {
    flex: 4;
    padding: 0px;
    margin-top: 0px;

    /* text-align: ; */
    /* display: flex; */
    /* align-items: center; */
    /* padding-right: 20px  ; */
    /* background-color: cyan; */
  }
  
  .userTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .userAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }
  
  .userContainer {
    display: flex;
    margin-top: 20px;
    /* margin-right: 20px; */
    

  }
  
  .userShow {
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .userUpdate {
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-left: 20px;
  }
  
  .userShowTop {
    display: flex;
    align-items: center;
  }
  
  .userShowImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .userShowImgIcon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
  .userShowTopTitle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  
  .userShowUsername {
    font-weight: 600;
    text-align: left;
  }
  
  .userShowUserTitle {
    font-weight: 300;
    text-align: left;

  }
  
  .userShowBottom{
      margin-top: 20px;
  }
  
  .userShowTitle {
    font-size: 14px;
    font-weight: 600;
    justify-content: left;
    display: flex;
    color: rgb(175, 170, 170);
  }
  
  .userShowInfo{
      display: flex;
      align-items: center;
      margin: 20px 0px;
      color: #444;
  }
  
  .userShowIcon{
      font-size: 20px !important;
  }
  
  .userShowInfoTitle{
      margin-left: 10px;
  }
.userShowInfoTitleBold{
    margin-left: 10px;
    font-weight: bold;
    text-align: left;
}
  
  .userUpdateTitle{
      font-size: 24px;
      font-weight: 600;
  }
  
  .userUpdateForm{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
  }
  
  .userUpdateItem{
      display: flex;
      flex-direction: column;
      margin-top: 10px;
  }
  
  .userUpdateItem>label{
      margin-bottom: 5px;
      font-size: 14px;
  }
  
  .userUpdateInput{
      border: none;
      width: 250px;
      height: 30px;
      border-bottom: 1px solid gray;
  }
  
  .userUpdateRight{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  
  .userUpdateUpload{
      display: flex;
      align-items: center;
  }
  
  .userUpdateImg{
      width: 100px;
      height: 100px;
      border-radius: 10px;
      object-fit: cover;
      margin-right: 20px;
  }
  
  .userUpdateIcon{
      cursor: pointer;
  }
  
  .userUpdateButton{
      border-radius: 5px;
      border: none;
      padding: 5px;
      cursor: pointer;
      background-color: darkblue;
      color: white;
      font-weight: 600;
  }